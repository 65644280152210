import React, { useState } from 'react';
import './App.css';

function App() {
  const [input, setInput] = useState('');
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);  // 添加这行

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);  // 设置 loading 为 true
    try {
      const response = await fetch('https://testservice.ries.ai/api/embed', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: input }),
      });
      const data = await response.json();
      setTableData(data);
    } catch (error) {
      console.error('Error:', error);
    }
    setLoading(false);  // 设置 loading 为 false
  };

  return (
    <div className="App">
      <div style={{ textAlign: 'center' }}>
        <h4>RIES 排序策略测试</h4>
      </div>
      <form onSubmit={handleSubmit}>
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Enter your text here"
          rows="3"
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Processing...' : 'Process'}
        </button>
      </form>

      {tableData && (
        <div>
          <table>
            <thead>
              <tr>
                {tableData.headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex} className={cellIndex === 1 ? "similarity" : ""}>
                      {cellIndex === 1 ? cell.toFixed(4) : cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default App;
